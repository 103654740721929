import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3408219f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "addon" }
const _hoisted_2 = { class: "choose-option" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "check"
}
const _hoisted_5 = {
  key: 1,
  class: "check"
}
const _hoisted_6 = {
  key: 0,
  class: "extra-price"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("b", {
      class: _normalizeClass(["addon-title", { 'has-error': _ctx.hasError }])
    }, [
      _createTextVNode(_toDisplayString(_ctx.addon.name) + " ", 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("itemAddonSingle.select")), 1)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addon.addons, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["addon-option", { active: _ctx.selectedOption === option.id, 'addon-hidden': option.hidden !== undefined && option.hidden === true }]),
        key: option.id,
        onClick: ($event: any) => (_ctx.selectOption(option.id))
      }, [
        (_ctx.selectedOption === option.id)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_ion_icon, { icon: _ctx.checkmarkCircleOutline }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedOption !== option.id)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_ion_icon, { icon: _ctx.ellipseOutline }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createTextVNode(_toDisplayString(option.name) + " ", 1),
          (option.price !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "+" + _toDisplayString(_ctx.$filters.currency(option.price)), 1))
            : _createCommentVNode("", true)
        ])
      ], 10, _hoisted_3))
    }), 128))
  ]))
}