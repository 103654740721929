
import { defineComponent } from "vue";
import { NumberSpinner } from "vue3-number-spinner";
import { IonIcon } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import axios from "axios";
import PaymentBase from "./PaymentBase.vue";

function roundToDigits(number: number, digits: number) {
  return Number(Math.ceil(Number(number + 'e' + digits)) + 'e-' + digits);
}

export default defineComponent({
  props: ["paymentInfo"],
  name: "SplitBill",
  data() {
    return {
      numPax: 2,
    };
  },
  mixins: [PaymentBase],
  setup() {
    return { arrowBackOutline };
  },
  components: {
    NumberSpinner,
    IonIcon,
  },
  beforeMount() {
    if (this.paymentInfo && this.paymentInfo.split_bill_num_pax !== 0) {
      this.numPax = this.paymentInfo.split_bill_num_pax;
    }
  },
  mounted() {
    this.$events.events.on("splitBillChange", (e: any) => {
      this.numPax = e.numPax;
    });
  },
  methods: {
    updateNumPax(numPax: number) {
      this.numPax = numPax;
      axios.post("/orders/in-store/split-bill-num-pax", {
        split_bill_num_pax: this.numPax,
      });
    },
  },
  computed: {
    total() {
      return roundToDigits(this.paymentInfo.total / this.numPax, 2);
    },
  },
});
