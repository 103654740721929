import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";
import RestaurantHome from "../views/RestaurantHome.vue";
import RestaurantAdmin from "../views/RestaurantAdmin.vue";
import RestaurantCart from "../views/RestaurantCart.vue";
import RestaurantPaymentConfirmation from "../views/RestaurantPaymentConfirmation.vue";
import store from "../store";
import ExpiredSession from "@/views/ExpiredSession.vue";
import DisabledTable from "@/views/DisabledTable.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/admin",
    name: "RestaurantAdmin",
    component: RestaurantAdmin,
  },
  {
    path: "/in-place-order/start",
    name: "RestaurantLogin",
    component: RestaurantHome,
  },
  {
    path: "/in-place-order",
    name: "RestaurantHome",
    component: RestaurantHome,
  },
  {
    path: "/in-place-order/cart",
    name: "RestaurantCart",
    component: RestaurantCart,
  },
  {
    path: "/payment-confirmation",
    name: "RestaurantPaymentConfirmation",
    component: RestaurantPaymentConfirmation,
  },
  {
    path: "/expired",
    name: "ExpiredSession",
    component: ExpiredSession,
  },
  {
    path: "/disabled",
    name: "DisabledTable",
    component: DisabledTable,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   // ...
//   // explicitly return false to cancel the navigation
//   console.info(store.state.count);
//   return true;
// });

export default router;
