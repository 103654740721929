import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48df3c96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_IonSelectOption = _resolveComponent("IonSelectOption")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_tip = _resolveComponent("tip")!
  const _component_payments_summary_card = _resolveComponent("payments-summary-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.paymentInfo.payment_type === null)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn-back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
        }, [
          _createVNode(_component_ion_icon, { icon: _ctx.arrowBackOutline }, null, 8, ["icon"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("payment.back")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("payment.paymentType3Sub")), 1),
    _createVNode(_component_ion_select, {
      modelValue: _ctx.payOtherId,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payOtherId) = $event)),
      interface: "popover",
      placeholder: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherParts, (usrSession) => {
          return (_openBlock(), _createBlock(_component_IonSelectOption, {
            value: usrSession.id,
            key: usrSession.id
          }, {
            default: _withCtx(() => [
              (!usrSession.current)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(usrSession.name), 1))
                : _createCommentVNode("", true),
              (usrSession.current)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("payment.myPart")), 1))
                : _createCommentVNode("", true),
              _createElementVNode("b", null, " (" + _toDisplayString(_ctx.$filters.currency(usrSession.total)) + ")", 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_tip, {
      total: _ctx.paymentInfo.total_my_items,
      onTipUpdated: _ctx.handleTipUpdated
    }, null, 8, ["total", "onTipUpdated"]),
    _createVNode(_component_payments_summary_card),
    _createElementVNode("button", {
      class: "primary-btn",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handlePay && _ctx.handlePay(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("payment.pay")) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$filters.currency(_ctx.total + _ctx.tip)), 1)
    ]),
    _createElementVNode("small", {
      class: "payment-alert",
      innerHTML: _ctx.$t('payment.conditions')
    }, null, 8, _hoisted_3)
  ]))
}