
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import QrScanner from "qr-scanner"; // if installed via package and bundling with a module bundler like webpack or rollup

export default defineComponent({
  name: "ExpiredSession",
  components: {
    IonContent,
    IonPage,
  },
  data() {
    return {
      qrScanner: null as any,
    };
  },
  methods: {
    openQrScanner() {
      const qrScanner = new QrScanner(
        //@ts-ignore
        document.getElementById("video"),
        //@ts-ignore
        (result) => {
          qrScanner.stop();
          window.location.replace(result.data);
        },
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      this.qrScanner = qrScanner;

      qrScanner.start().then(() => {
        // updateFlashAvailability();
        // // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
        // // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
        // // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
        // // start the scanner earlier.
        // QrScanner.listCameras(true).then((cameras) =>
        //   cameras.forEach((camera) => {
        //     const option = document.createElement("option");
        //     option.value = camera.id;
        //     option.text = camera.label;
        //     camList.add(option);
        //   })
        // );
      });
    },
  },
});
