import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_ion_router_outlet, { onWaiterRequests: _ctx.handleWaiterRequests }, null, 8, ["onWaiterRequests"]),
        (_ctx.isAdmin && _ctx.$route.name !== 'RestaurantAdmin')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSessionOptions && _ctx.showSessionOptions(...args))),
              class: "admin-btn"
            }, [
              _createVNode(_component_ion_icon, { icon: _ctx.settingsOutline }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isAdmin && _ctx.waiterRequests)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.waiterAlert && _ctx.waiterAlert(...args))),
              class: "waiter-btn"
            }, [
              _createVNode(_component_ion_icon, { icon: _ctx.notificationsOutline }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}