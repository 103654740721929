
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import PaymentBase from "./PaymentBase.vue";

export default defineComponent({
  props: ["paymentInfo"],
  components: {
    IonIcon,
  },
  mixins: [PaymentBase],
  setup() {
    return { arrowBackOutline };
  },
  name: "PayAll",
});
