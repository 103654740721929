import Emitter from "pico-emitter";
import { TableEvents } from "./services/table-events";
import Echo from "laravel-echo";

export default function setupWebsockets() {
    //@ts-ignore
    window.Pusher = require("pusher-js");

    const authErrorEvents = new Emitter();

    const echo = new Echo({
    broadcaster: "pusher",
    key: "sq7HjrUOBfKmC576ILgskD5srU870gJ7",
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    forceTLS: false,
    disableStats: true,
    authEndpoint: process.env.VUE_APP_WS_AUTH_ENDPOINT,
    authorizer: (channel: any, options: any) => {
            return {
                authorize: (socketId: any, callback: any) => {
                    fetch(process.env.VUE_APP_WS_AUTH_ENDPOINT, {
                    method: "POST",
                    //@ts-ignore
                    headers: {
                        "Content-Type": "application/json",
                        "Table-Session": localStorage.getItem("tableSession"),
                    },
                    body: JSON.stringify({
                        socket_id: socketId,
                        channel_name: channel.name,
                    }),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        callback(false, data);
                    })
                    .catch((error) => {
                        console.error('echo callback auth error');
                        authErrorEvents.emit("authError");
                        callback(true, error);
                    });
                },
            };
        },
    });

    const tableEvents = new TableEvents(echo, authErrorEvents);

    return [echo, tableEvents] as const;
}