
import { IonContent, IonPage, IonIcon, IonModal } from "@ionic/vue";
import { defineComponent } from "vue";
import _ from "lodash";
import QrScanner from "qr-scanner";
import axios from "axios";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "RestaurantAdmin",
  setup() {
    return { closeOutline };
  },
  components: {
    IonContent,
    IonPage,
    IonModal,
  },
  data() {
    return {
      tableSessionsOpen: false,
      sessions: [] as any,
      qrScanner: null as any,
    };
  },
  mounted() {
    this.openQrScanner();
  },
  beforeMount() {
    if (this.$route.query.token) {
      //@ts-ignore
      localStorage.setItem("adminSession", this.$route.query.token);
    }
  },
  methods: {
    openTableSession(item: any) {
      window.location.replace(item.url);
    },
    getTableSessions(tableCode: string) {
      axios.get("/orders/in-store/table/" + tableCode + "/sessions").then((resp: any) => {
        // window.location.replace(resp.data.url);
        this.sessions = resp.data;
        this.tableSessionsOpen = true;
      });
    },
    manualTableCode() {
      const res: any = window.prompt('Codi');
      this.getTableSessions(res);
    },
    openQrScanner() {
      const qrScanner = new QrScanner(
        //@ts-ignore
        document.getElementById("video"),
        //@ts-ignore
        (result) => {
          if (result.data.startsWith('https://qr.dinbook.es/')) {
            qrScanner.destroy();
            let tableCode = result.data.replace('https://qr.dinbook.es/', '');
            this.getTableSessions(tableCode);
          }
        },
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      this.qrScanner = qrScanner;

      qrScanner.start().then(() => {
        // updateFlashAvailability();
        // // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
        // // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
        // // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
        // // start the scanner earlier.
        // QrScanner.listCameras(true).then((cameras) =>
        //   cameras.forEach((camera) => {
        //     const option = document.createElement("option");
        //     option.value = camera.id;
        //     option.text = camera.label;
        //     camList.add(option);
        //   })
        // );
      });
    },
  },

});
