
import { defineComponent } from "vue";
import { IonIcon, toastController } from "@ionic/vue";
import { ellipseOutline, checkmarkCircleOutline } from "ionicons/icons";

export default defineComponent({
  props: ["addon", "hidden"],
  name: "ItemAddonSingle",
  setup() {
    return { ellipseOutline, checkmarkCircleOutline };
  },
  components: {
    IonIcon,
  },
  beforeMount() {
    // this.selectOption(this.addon.addons[0].id);
  },
  data() {
    return {
      selectedOption: null as any,
      hasError: false
    };
  },
  methods: {
    selectOption(optionId: number) {
      this.selectedOption = optionId;
      this.$emit("change", [this.selectedOption]);
    },
    async presentErrorToast() {
      const toast = await toastController.create({
        message: this.$t("itemAddonSingle.error", { name: this.addon.name }),
        duration: 3500,
        mode: "ios",
        color: "warning",
        position: "top",
      });

      await toast.present();
    },
    async validate() {
      return new Promise((resolve, reject) => {
        if (this.hidden) {
          resolve(true);
          return;
        }
        this.hasError = (this.selectedOption === null);
        if (this.hasError) {
          this.presentErrorToast();
        }
        resolve(!this.hasError);
      });
    }
  },
});
