
import { defineComponent } from "vue";
import { IonIcon, IonSelect, IonSelectOption } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import PaymentBase from "./PaymentBase.vue";
import PaymentsSummaryCard from "../PaymentsSummaryCard.vue";

export default defineComponent({
  props: ["paymentInfo"],
  components: {
    IonIcon,
    IonSelect,
    IonSelectOption,
    PaymentsSummaryCard,
  },
  mixins: [PaymentBase],
  data() {
    return {
      payOtherId: null,
    };
  },
  setup() {
    return { arrowBackOutline };
  },
  name: "PayMyItems",
  mounted() {
    const current = this.otherParts.filter((item: any) => item.current);
    this.payOtherId = (current.length === 0) ? this.otherParts[0].id : this.otherParts.filter((item: any) => item.current)[0].id;
  },
  methods: {
    handlePay() {
      this.$emit('pay', { user_session_id: this.payOtherId });
    }
  },
  computed: {
    otherParts() {
      return [...this.paymentInfo.others_parts].sort((a: any, b: any) => Number(b.current) - Number(a.current)).filter((item: any) => item.total > item.amount_paid);
    },
    total() {
      if (this.payOtherId !== null) return this.paymentInfo.others_parts.filter((item: any) => item.id === this.payOtherId)[0].total;
      return this.paymentInfo.total_my_items;
    },
  },
});
