import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ab3bec1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_tip = _resolveComponent("tip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.paymentInfo.payment_type === null)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn-back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
        }, [
          _createVNode(_component_ion_icon, { icon: _ctx.arrowBackOutline }, null, 8, ["icon"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("payment.back")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("payment.payAllTable")), 1),
    _createVNode(_component_tip, {
      total: _ctx.paymentInfo.total,
      onTipUpdated: _ctx.handleTipUpdated
    }, null, 8, ["total", "onTipUpdated"]),
    _createElementVNode("button", {
      class: "primary-btn",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('pay')))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("payment.pay")) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$filters.currency(_ctx.paymentInfo.total + _ctx.tip)), 1)
    ]),
    _createElementVNode("small", {
      class: "payment-alert",
      innerHTML: _ctx.$t('payment.conditions')
    }, null, 8, _hoisted_1)
  ]))
}