
import { IonContent, IonPage, IonIcon, IonSpinner } from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import OrderItems from "./components/OrderItems.vue";
import PaymentModal from "./components/PaymentModal.vue";
import PaymentsSummaryCard from "./components/PaymentsSummaryCard.vue";
import _ from "lodash";
import { arrowBackOutline } from "ionicons/icons";

export default defineComponent({
  name: "RestaurantCart",
  setup() {
    return { arrowBackOutline };
  },
  components: {
    IonContent,
    IonPage,
    IonIcon,
    PaymentModal,
    OrderItems,
    PaymentsSummaryCard,
    IonSpinner,
  },
  data() {
    return {
      loading: false,
      placingOrder: false,
      itemsByUser: [],
      payNow: false,
      showAddComment: false,
      comment: null
    };
  },
  ionViewWillEnter() {
    this.getCart();
  },
  beforeMount() {
    if (this.$route.query.payment === "true") {
      this.placeOrder(true);
    }
  },
  mounted() {
    this.$events.events.on("addedItem", () => {
      this.getCart(false);
    });
    this.$events.events.on("updatedItem", () => {
      this.getCart(false);
    });
    this.$events.events.on("openPayment", () => {
      this.placeOrder(true);
    });
  },
  methods: {
    getCart(showLoading = true) {
      if (showLoading) this.loading = true;
      axios.get("/orders/in-store/cart").then((resp: any) => {
        this.itemsByUser = resp.data.items_by_user.map((user: any) => {
          user.opened = user.is_current_user;
          return user;
        });
        this.payNow = resp.data.in_store_orders_payment_required;
        this.loading = false;
      });
    },
    handleShowAddComment() {
      this.showAddComment = true;
      this.$nextTick(() => {
        //@ts-ignore
        this.$refs.commentTextarea.focus();
      });
    },
    openHome() {
      this.$router.back();
      // this.$router.replace({
      //   path: "/in-place-order",
      // });
    },
    placeOrder(payment = false) {
      this.placingOrder = true;
      axios
        .post("/orders/in-store/place-order", { force_payment: payment, comment: this.comment })
        .then((resp) => {
          this.handleOrderSuccess();
          this.getCart(true);
        })
        .catch((err) => {
          // Payment required
          if (err.response.status === 402) {
            console.info(
              "payment required",
              err.response.data.available_payment_types
            );
            //@ts-ignore
            this.$refs.paymentModal.open();
          }
        })
        .finally(() => {
          this.placingOrder = false;
        });
    },
    async handleOrderSuccess() {
      try {
        //@ts-ignore
        await this.$refs.paymentModal.dismiss();
      } catch (err) {
        console.log(err);
      }
      this.$nextTick(() => {
        this.$router.replace({ path: "/payment-confirmation" });
      });
    }
  },
  computed: {
    totalSent() {
      //@ts-ignore
      return _.sumBy(this.sentItems, (user: any) => {
        if (user.is_current_user || this.payNow) {
          return _.sumBy(user.items, function (item: any) {
            return item.price;
          });
        }
        return 0;
      });
    },
    totalPending() {
      //@ts-ignore
      return _.sumBy(this.activeItems, (user: any) => {
        if (user.is_current_user || this.payNow) {
          return _.sumBy(user.items, function (item: any) {
            return item.price;
          });
        }
        return 0;
      });
    },
    hasSentItems() {
      let hasSentItems = false;
      for (const user of this.itemsByUser) {
        //@ts-ignore
        for (const item of user.items) {
          if (!item.current) {
            hasSentItems = true;
          }
        }
      }
      return hasSentItems;
    },
    sentItems() {
      //@ts-ignore
      return this.itemsByUser.map((user: any) => {
        let parsedUsr = Object.assign({}, user);
        parsedUsr.items = user.items.filter((item: any) => {
          return !item.current;
        });
        return parsedUsr;
      });
    },
    hasActiveItems() {
      let hasActiveItems = false;
      for (const user of this.itemsByUser) {
        //@ts-ignore
        for (const item of user.items) {
          if (item.current) {
            hasActiveItems = true;
          }
        }
      }
      return hasActiveItems;
    },
    activeItems() {
      //@ts-ignore
      return this.itemsByUser.map((user: any) => {
        let parsedUsr = Object.assign({}, user);
        parsedUsr.items = user.items.filter((item: any) => {
          return item.current;
        });
        return parsedUsr;
      });
    },
  },
});
