import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e0eb6b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qtt-btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_number_spinner = _resolveComponent("number-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setQtt(_ctx.qtt - 1))),
      class: "btn-number"
    }, [
      _createVNode(_component_ion_icon, { icon: _ctx.removeCircle }, null, 8, ["icon"])
    ]),
    _createVNode(_component_number_spinner, {
      modelValue: _ctx.qtt,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.qtt) = $event)),
      min: 1,
      max: 100
    }, null, 8, ["modelValue"]),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setQtt(_ctx.qtt + 1))),
      class: "btn-number"
    }, [
      _createVNode(_component_ion_icon, { icon: _ctx.addCircle }, null, 8, ["icon"])
    ])
  ]))
}