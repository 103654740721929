
import { defineComponent } from "vue";
import axios from "axios";
import { close } from "ionicons/icons";
import { IonModal, IonIcon, loadingController, IonList, IonItem, IonSpinner } from "@ionic/vue";
import QuantityButtons from "@/views/components/QuantityButtons.vue";

export default defineComponent({
  setup() {
    return { close };
  },
  name: "EditCartItemModal",
  components: {
    IonModal,
    IonIcon,
    QuantityButtons,
    IonList, IonItem, IonSpinner
  },
  data() {
    return {
      loading: false,
      showCartItemEditModal: false,
      showSelectCartItem: false,
      itemToEdit: null as any,
      cartItems: [] as any,
    };
  },
  methods: {
    async editItem(item: any, isCurrentUser: boolean) {
      // const loading = await loadingController.create({});
      // loading.present();
      this.showCartItemEditModal = true;
      this.loading = true;
      axios.get(`/orders/in-store/item/${item.id}/orderitems`).then((resp) => {
        this.cartItems = resp.data.orderitems;
        if (this.cartItems.length === 1) {
          this.showSelectCartItem = false;
          this.editCartItem(this.cartItems[0], isCurrentUser);
        } else {
          this.showSelectCartItem = true;
        }
        // this.$emit("update");
      }).finally(() => {
        // loading.dismiss();
        this.loading = false;
      });
    },
    editCartItem(item: any, isCurrenUser: boolean) {
      if ((!isCurrenUser || !item.current) && (localStorage.getItem("adminSession") === null)) return;
      this.itemToEdit = Object.assign({}, item);
      this.showCartItemEditModal = true;
    },
    async updateCartItem(item: any) {
      this.showCartItemEditModal = false;
      const loading = await loadingController.create({});
      loading.present();
      axios.put(`/orders/in-store/item/${item.id}`, item).then((resp) => {
        this.$emit("update", resp.data);
      }).finally(() => {
        loading.dismiss();
      });
    },
    async removeCartItem(item: any) {
      this.showCartItemEditModal = false;
      const loading = await loadingController.create({});
      loading.present();
      axios.delete(`/orders/in-store/item/${item.id}`).then((resp) => {
        this.$emit("update", resp.data);
      }).finally(() => {
        loading.dismiss();
      });
    },
  },
});
