import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a6ede86"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "order-item-qtt" }
const _hoisted_4 = { class: "order-item-content" }
const _hoisted_5 = { class: "item-name" }
const _hoisted_6 = { class: "order-item-addons" }
const _hoisted_7 = { class: "order-item-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_cart_item_modal = _resolveComponent("edit-cart-item-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsByUser, (user) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: user.id
      }, [
        (user.items.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["person-row-container", {
        'other-person-row': !user.is_current_user,
        opened: _ctx.openedUsers.includes(user.id) || user.is_current_user,
      }])
            }, [
              (!user.is_current_user)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "person-row",
                    onClick: ($event: any) => (_ctx.toggleUser(user))
                  }, [
                    _createTextVNode(_toDisplayString(user.name), 1),
                    _createElementVNode("span", null, _toDisplayString(user.items.length), 1)
                  ], 8, _hoisted_1))
                : _createCommentVNode("", true),
              _createVNode(_TransitionGroup, {
                name: "list",
                tag: "ul",
                class: "order-items"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(user.items, (item) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "order-item",
                      key: item.id,
                      onClick: ($event: any) => (_ctx.$refs.editCartItemModal.editCartItem(item, user.is_current_user))
                    }, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(item.quantity) + "x", 1),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("small", null, _toDisplayString(item.addons.map((entry) => entry.addon_name).join(", ")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$filters.currency(item.price)), 1)
                    ], 8, _hoisted_2))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ], 2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    _createVNode(_component_edit_cart_item_modal, {
      ref: "editCartItemModal",
      onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update')))
    }, null, 512)
  ]))
}