
import { defineComponent } from "vue";
import Tip from "./Tip.vue";

export default defineComponent({
  name: "PaymentBase",
  components: {
    Tip,
  },
  data() {
    return {
      tip: 0,
    };
  },
  methods: {
    handleTipUpdated(amount: number) {
      this.tip = amount;
      this.$emit("tip-updated", amount);
    },
  },
});
