
import { IonContent, IonPage, loadingController, toastController, alertController } from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import PaymentsSummaryCard from "./components/PaymentsSummaryCard.vue";

export default defineComponent({
  name: "RestaurantPaymentConfirmation",
  components: {
    IonContent,
    IonPage,
    PaymentsSummaryCard
  },
  data() {
    return {
      paymentInfo: null,
    };
  },
  mounted() {
    this.$events.events.on("userHasPaid", () => {
      this.getPaymentInfo();
    });
  },
  ionViewWillEnter() {
    this.getPaymentInfo();
  },
  methods: {
    getPaymentInfo() {
      axios.get("/orders/in-store/payment-info").then((resp: any) => {
        this.paymentInfo = resp.data;
      });
    },
    newOrder() {
      axios.get("/orders/in-store/new-order").then((resp: any) => {
        window.location.replace(resp.data.url);
      });
    },
    async emailTicket(email: string) {
      const loading = await loadingController.create({});
      loading.present();

      axios.post("/orders/in-store/email-ticket", { email }).then(async (resp: any) => {
        const toast = await toastController.create({
          message: this.$t("confirmation.emailSentConfirmation"),
          duration: 2500,
          mode: "ios",
          position: "top",
        });

        await toast.present();
      })
        .finally(() => {
          loading.dismiss();
        });
    },
    async openEmailTicketDialog() {
      const alert = await alertController.create({
        header: this.$t("confirmation.sentByEmail"),
        buttons: [
          {
            text: this.$t("confirmation.emailSendButton"),
            role: "confirm",
            handler: (form) => {
              if (form.email !== '' && form.email !== undefined) {
                this.emailTicket(form.email);
              }
            },
          },
        ],
        inputs: [
          {
            type: "text",
            name: "email",
            attributes: {
              inputmode: "email",
            },
          },
        ],
      });

      await alert.present().then(() => {
        const firstInput: any = document.querySelector("ion-alert input");
        firstInput.focus();
        return;
      });
    },
    payAnother() {
      this.$router.push({
        path: "/in-place-order/cart",
        //@ts-ignore
        query: { payment: true },
      });
    },
    continueOrder() {
      this.$router.replace({
        path: "/in-place-order",
      });
    },
  },
  computed: {},
});
