import ca from "./ca.json";
import es from "./es.json";
import de from "./de.json";
import fr from "./fr.json";
import it from "./it.json";
import en from "./en.json";
import ru from "./ru.json";
import ar from "./ar.json";

export default {
  ca,
  es,
  de,
  fr,
  it,
  en,
  ru,
  ar,
};
