
import {
  IonContent,
  IonPage,
  modalController,
  IonIcon,
  loadingController, toastController
} from "@ionic/vue";
import { defineComponent } from "vue";
import _ from "lodash";
import axios from "axios";
import { closeOutline, add, cardOutline, cashOutline } from "ionicons/icons";
import { debounce } from 'lodash';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

export default defineComponent({
  props: ["item"],
  name: "ItemDetailModal",
  setup() {
    return { closeOutline, add, cardOutline, cashOutline };
  },
  components: {
    IonContent,
    IonPage,
    // VueTypeaheadBootstrap,
    SimpleTypeahead
  },
  data() {
    return {
      form: {
        printer_id: null,
        client_id: null,
        name: null,
        id_doc: null,
        address: null,
      },
      printers: [] as any,
      clients: [] as any
    };
  },
  mounted() {
    this.getPrinters();
  },
  methods: {
    handleSelectClient(client: any) {
      this.form.name = client.name;
      this.form.id_doc = client.id_doc;
      this.form.address = client.address;
      this.form.client_id = client.id;
    },
    async print() {
      const loading = await loadingController.create({});
      loading.present();
      axios
        .post("/orders/in-store/print-client-ticket", this.form)
        .then(async (resp) => {
          const toast = await toastController.create({
            message: "S'ha imprimit el ticket",
            duration: 2500,
            mode: "ios",
            position: "top",
          });

          await toast.present();
        })
        .finally(() => {
          loading.dismiss();
          this.closeModal();
        });
    },
    lookupClients: debounce(function (ev) {
      //@ts-ignore
      this.form.name = ev.input;
      //@ts-ignore
      this.form.client_id = null;
      axios
        //@ts-ignore
        .post("/orders/in-store/query-clients", this.form)
        .then((resp) => {
          //@ts-ignore
          this.clients = resp.data;
                    //@ts-ignore
          console.log(this.clients);
        })
    }, 500),
    closeModal() {
      modalController.dismiss();
    },
    async getPrinters() {
      axios
        .get("/orders/in-store/printers")
        .then((resp) => {
          this.printers = resp.data;
          if (this.printers.length > 0) {
            this.form.printer_id = this.printers[0].id;
          }
        });
    },
  },
  computed: {

  },
});
