
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import IntlRelativeFormat from "intl-relativeformat";

export default defineComponent({
  name: "DisabledTable",
  components: {
    IonContent,
    IonPage,
  },
  data() {
    return {
      openDate: null,
      originalDate: null as any,
      isOpened: false,
    };
  },
  mounted() {
    const queryDate = this.$route.query.date;

    if (queryDate !== undefined) {
      //@ts-ignore
      const date = new Date(queryDate);
      this.originalDate = date;

      const rtf = new IntlRelativeFormat(this.$i18n.locale);
      //@ts-ignore
      this.openDate = rtf.format(date);
    }

    setInterval(() => {
      if (this.originalDate === null) return false;

      const rtf = new IntlRelativeFormat(this.$i18n.locale);
      //@ts-ignore
      this.openDate = rtf.format(this.originalDate);

      let diff = new Date().getTime() - this.originalDate.getTime();
      let isOpen = (diff > 0);
      if (isOpen) {
        this.isOpened = true;
      }
    }, 2000);
  },
  methods: {
    openLocation() {
      //@ts-ignore
      window.location.replace(this.$route.query.table_url);
   }
  }
});
