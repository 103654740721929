
import { IonApp, IonRouterOutlet, IonIcon, actionSheetController, modalController, alertController, loadingController, toastController } from "@ionic/vue";
import { defineComponent } from "vue";
import PaymentsManageModal from "@/views/components/PaymentsManageModal.vue";
import PrintClientTicketModal from "@/views/components/PrintClientTicketModal.vue";
import { settingsOutline, cardOutline, close, exitOutline, trashBinOutline, printOutline, notificationsOutline } from "ionicons/icons";
import axios from "axios";

export default defineComponent({
  name: "App",
  setup() {
    return { settingsOutline, cardOutline, close, exitOutline, trashBinOutline, printOutline, notificationsOutline };
  },
  components: {
    IonApp,
    IonRouterOutlet,
    IonIcon
  },
  data() {
    return {
      waiterRequests: false,
    };
  },
  mounted() {
    if (this.$route.query.tableSession === undefined) {
      this.$events.initEvents(localStorage.getItem("sessionId"));
    }
    //@ts-ignore
    this.$globalEvents.on('waiterRequests', (waiterRequests: boolean) => {
      this.waiterRequests = waiterRequests;
    });
  },
  methods: {
    async showSessionOptions() {
      const actionSheet = await actionSheetController.create({
        header: 'Accions',
        cssClass: 'my-custom-class',
        buttons: [
          {
            text: 'Gestionar pagaments',
            icon: cardOutline,
            data: {
              action: 'payments',
            },
          },
          {
            text: 'Tornar a imprimir cuina',
            icon: printOutline,
            data: {
              action: 'print',
            },
          },
          {
            text: 'Imprimir ticket client',
            icon: printOutline,
            data: {
              action: 'printClientTicket',
            },
          },
          {
            text: 'Netejar taula',
            icon: trashBinOutline,
            role: 'danger',
            data: {
              action: 'clean',
            },
          },
          {
            text: 'Sortir de la taula',
            icon: exitOutline,
            data: {
              action: 'backToAdmin',
            },
          },
          {
            text: 'Cancel·lar',
            icon: close,
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
      });
      actionSheet.present();

      const res = await actionSheet.onDidDismiss();
      if (res.data.action === 'payments') {
        this.openPaymentsManageModal();
      } else if (res.data.action === 'backToAdmin') {
        this.$router.replace({
          path: "/admin",
        });
      } else if (res.data.action === 'clean') {
        this.clearSession();
      } else if (res.data.action === 'print') {
        this.rePrint();
      } else if (res.data.action === 'printClientTicket') {
        this.openPrintClientTicketModal();
      }
    },
    async rePrint() {
      const loading = await loadingController.create({});
      loading.present();
      axios
        .get("/orders/in-store/print", {})
        .then(async (resp) => {
          const toast = await toastController.create({
            message: "S'ha enviat per imprimir a cuina",
            duration: 2500,
            mode: "ios",
            position: "top",
          });

          await toast.present();
        })
        .finally(() => {
          loading.dismiss();
        });
    },
    async clearSession() {
      const alert = await alertController.create({
        header: "Segur que vols realitzar aquesta acció?",
        message: "Al netejar la taula els comensals no podran afegir nous productes ni modificar els existents. Tampoc podran pagar si encara no han fet. Han de tornar a escanejar el QR de la taula per seguir demanant.",
        buttons: [
          {
            text: 'No fer res',
            role: 'cancel',
            handler: () => {
              // handlerMessage.value = 'Alert canceled';
            },
          },
          {
            text: 'Si, netejar',
            role: 'destructive',
            handler: async () => {
              const loading = await loadingController.create({});
              loading.present();

              axios
                .post("/orders/in-store/clear", {})
                .then(async (resp) => {
                  const toast = await toastController.create({
                    message: "Taula netejada",
                    duration: 2500,
                    mode: "ios",
                    position: "top",
                  });

                  await toast.present();
                })
                .finally(() => {
                  loading.dismiss();
                });

            },
          },
        ],
      });

      await alert.present();
    },
    async openPaymentsManageModal() {
      const modal = await modalController.create({
        component: PaymentsManageModal,
      });

      // modal.onDidDismiss().then((resp: any) => {
      // });

      return await modal.present();
    },
    async openPrintClientTicketModal() {
      const modal = await modalController.create({
        component: PrintClientTicketModal,
      });

      // modal.onDidDismiss().then((resp: any) => {
      // });

      return await modal.present();
    },
    async waiterAlert() {
      const alert = await alertController.create({
        header: this.$t("home.requestWaiterTitle"),
        message: this.$t("home.requestWaiterDesc"),
        inputs: [
          {
            type: 'textarea',
            placeholder: this.$t("home.requestWaiterReason"),
          },
        ],
        buttons: [
          {
            text: this.$t("common.cancel"),
            role: "cancel"
          },
          {
            text: this.$t("common.send"),
            role: "confirm",
            handler: async (form) => {
              const loading = await loadingController.create({});
              loading.present();
              axios
                .post("/orders/in-store/waiter-request", {message: form[0]})
                .then(async (resp) => {
                  const toast = await toastController.create({
                    message: this.$t("home.requestWaiterConfirmation"),
                    duration: 2500,
                    mode: "ios",
                    position: "top",
                  });

                  await toast.present();
                })
                .finally(() => {
                  loading.dismiss();
                });
            },
          },
        ],
      });
      alert.present();
    },
  },
  computed: {
    isAdmin() {
      return localStorage.getItem("adminSession") !== null
    }
  }
});
