import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-301ae520"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tips-container" }
const _hoisted_2 = { class: "tip-title" }
const _hoisted_3 = { class: "tips-list" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("payment.tipTitle")), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amountOptions, (option, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: index,
          class: _normalizeClass({ selected: option === _ctx.selectedAmount }),
          onClick: ($event: any) => (_ctx.selectedAmount = option)
        }, _toDisplayString(_ctx.$filters.currency(option)), 11, _hoisted_4))
      }), 128)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputCustomAmount && _ctx.inputCustomAmount(...args)))
      }, _toDisplayString(_ctx.$t("payment.tipOtherQtt")), 1)
    ])
  ]))
}