import axios from "axios";
import { alertController } from "@ionic/vue";

export function setupRequestInterceptors() {

    axios.interceptors.request.use(
        function (config) {
            const tableSession = localStorage.getItem("tableSession");
            if (tableSession !== null) {
                //@ts-ignore
                config.headers["Table-Session"] = tableSession;
            }

            const adminSession = localStorage.getItem("adminSession");
            if (adminSession !== null) {
                //@ts-ignore
                config.headers["Admin-Session"] = adminSession;
            }
            
            const userToken = localStorage.getItem("userToken");
            if (userToken !== null) {
                //@ts-ignore
                config.headers["User-Token"] = userToken;
            }
            
            const over18 = localStorage.getItem("over18");
            if (over18 !== null) {
                //@ts-ignore
                config.headers["Over-18"] = over18;
            }

            const locale = localStorage.getItem("locale") ?? "ca";
            //@ts-ignore
            config.headers["Language"] = locale;
            //@ts-ignore
            config.headers["X-Socket-Id"] = window.Echo.socketId();
            
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

}
export function setupResponseInterceptors(router: any, tableEvents: any) {
  
    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function (error) {
            if (error.response.status === 410) {
                if (router.currentRoute.value.name !== 'RestaurantPaymentConfirmation') {
                    localStorage.removeItem("sessionId");
                    router.replace("/expired");
                }
            }

            if (error.response.status === 423) {
                const alert = await alertController.create({
                    message:
                    "No es pot realitzar aquesta acció perquè la comanda està pendent de pagament.",
                    buttons: [
                    {
                        text: "Anar al pagament",
                        role: "confirm",
                        handler: () => {
                        tableEvents.emitEvent("openPayment");
                        router.replace("/in-place-order/cart?payment=true");
                        },
                    },
                    ],
                });
                alert.present();
            }

            if (error.response.status === 420) {
                // const alert = await alertController.create({
                //     message:
                //         "Aquesta taula no permet comandes temporalment. Prova-ho més tard o contacta amb el personal.",
                //     buttons: [],
                // });
                // alert.present();
                router.replace("/disabled?date=" + error.response.data.closed_until + "&table_url=" + error.response.data.table_url);
            }

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        }
    );
}