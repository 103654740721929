
import { defineComponent } from "vue";
import { NumberSpinner } from "vue3-number-spinner";
import { IonIcon } from "@ionic/vue";
import { removeCircle, addCircle } from "ionicons/icons";

export default defineComponent({
  props: {
    modelValue: Number,
  },
  emits: ["update:modelValue"],
  setup() {
    return { removeCircle, addCircle };
  },
  components: {
    NumberSpinner,
    IonIcon,
  },
  data() {
    return {
      qtt: this.modelValue,
    };
  },
  watch: {
    qtt(newQtt) {
      this.$emit("update:modelValue", newQtt);
    },
  },
  methods: {
    setQtt(newQtt: any) {
      this.qtt = newQtt;
    },
  },
  name: "QuantityButtons",
});
