import Emitter from "pico-emitter";

export class TableEvents {
  public events;
  public echo: any;
  public listening = false;

  constructor(echo: any, authErrorEvents: Emitter) {
    this.echo = echo;
    this.events = new Emitter();
    authErrorEvents.on('authError', () => {
      this.listening = false;
    });
  }

  emitEvent(name: string, payload = {}) {
    this.events.emit(name, payload);
  }

  // Métodos
  initEvents(sessionId: number) {
    if (this.listening || sessionId === undefined || sessionId === null) return;
      this.listening = true;
      this.echo
      .join(`tableSession.${sessionId}`)
        .here(() => {
          console.log("here");
        })
        .joining((user: any) => {
          console.log(user);
        })
        .leaving((user: any) => {
          console.log(user);
        })
        .error((error: any) => {
          console.error(error);
        })
        .listen("InStorePaymentTypeSet", (e: any) => {
          this.events.emit("paymentTypeSet", e);
        })
        .listen("InStorePaymentPickUpdated", (e: any) => {
          this.events.emit("paymentPickUpdated", e);
        })
        .listen("InStoreUserHasPaid", (e: any) => {
          this.events.emit("userHasPaid", e);
        })
        .listen("InStoreAddedItem", (e: any) => {
          this.events.emit("addedItem", e);
        })
        .listen("InStoreUpdatedItem", (e: any) => {
          this.events.emit("updatedItem", e);
        })
        .listen("InStorePaymentSplitBillChanged", (e: any) => {
          this.events.emit("splitBillChange", e);
        });
  }
}
