
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { squareOutline, checkboxOutline } from "ionicons/icons";

export default defineComponent({
  props: ["addon"],
  name: "ItemAddonMultiple",
  setup() {
    return { squareOutline, checkboxOutline };
  },
  components: {
    IonIcon,
  },
  data() {
    return {
      selectedOption: [] as any,
    };
  },
  watch: {
    addon: {
      handler(newValue, oldValue) {
        this.handleAddonChange();
      },
      deep: true
    }
  },
  methods: {
    selectOption(optionId: number) {
      if (this.selectedOption.includes(optionId)) {
        this.selectedOption.splice(this.selectedOption.indexOf(optionId), 1);
      } else {
        this.selectedOption.push(optionId);
      }
      this.$emit("change", this.selectedOption);
    },
    handleAddonChange() {
      const available = this.addon.addons.filter((option: any) => !option.hidden).map((option: any) => { return option.id });
      if (available.length !== this.selectedOption.length) {
        this.selectedOption = this.selectedOption.filter((optionId: any) => available.includes(optionId))
        // this.$emit("change", this.selectedOption);
      }
    }
  },
  computed: {
    hasReachedLimit() {
      let limit = this.addon.limit;
      if (limit === 0) return 0;
      return this.selectedOption.length === limit;
    },
  },
});
