
import {
  modalController,
  IonItem, IonLabel, IonList,
  IonListHeader,
  IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonFab, IonFabButton, IonFabList, IonIcon
} from "@ionic/vue";
import { defineComponent } from "vue";
import _ from "lodash";
import axios from "axios";
import { checkmarkCircleOutline } from "ionicons/icons";

export default defineComponent({
  props: ["item"],
  name: "ItemDetailModal",
  setup() {
    return { checkmarkCircleOutline  };
  },
  components: {
    IonItem, IonList
  },
  data() {
    return {
      quantity: 1,
      showAllergensDetails: false,
      payments: [] as any,
      paymentInfo: null as any,
    };
  },
  mounted() {
    this.getPaymentInfo();
    this.getPayments();
    this.$events.events.on("userHasPaid", (e: any) => {
      this.getPaymentInfo();
      this.getPayments();
    });
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    async getPayments() {
      axios
        .get("/orders/in-store/payments")
        .then((resp) => {
          this.payments = resp.data;
        });
    },
    getPaymentInfo() {
      axios.get("/orders/in-store/payment-info").then((resp: any) => {
        this.paymentInfo = resp.data;
      });
    },
  },
  computed: {
    pendingPayment() {
      return this.paymentInfo.total_calc - this.paymentInfo.total_paid;
    },
    completedPayments() {
      return this.payments.filter((payment: any) => {return payment.status === 1 });
    }
  },
});
