import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import './registerServiceWorker';

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import VueObserveVisibility from "vue-observe-visibility";
import "vue-progressive-image/dist/style.css";
/* Theme variables */
import "./theme/variables.css";

import axios from "axios";
import store from "./store/index";

import { setupRequestInterceptors, setupResponseInterceptors } from './setupInterceptors'
import setupI18n  from './setupI18n'
import setupWebsockets  from './setupWebsockets'
import setupErrorTracking  from './setupErrorTracking'
import Emitter from "pico-emitter";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const i18n = setupI18n();

setupRequestInterceptors();

const [echo, tableEvents] = setupWebsockets();

setupResponseInterceptors(router, tableEvents);

const globalEvents = new Emitter();

const app = createApp(App)
  .provide("$store", store)
  .provide("$echo", echo)
  .use(IonicVue)
  .use(store)
  .use(i18n)
  .use(VueObserveVisibility);

app.config.globalProperties.$globalEvents = globalEvents;

app.config.globalProperties.$filters = {
  currency(value: any) {
    if (typeof value !== "number") {
      value = parseFloat(value);
    }
    const formatter = new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "EUR",
    });
    return formatter.format(value);
  },
};

setupErrorTracking(app, router);

app.use(router);

app.config.globalProperties.$store = store;
app.config.globalProperties.$echo = echo;
//@ts-ignore
window.Echo = echo;

app.config.globalProperties.$events = tableEvents;

router.isReady().then(() => {
  app.mount("#app");
});

window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
});