
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 200,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
  },
  name: "TextLimit",
  data() {
    return {
      limited: true,
    };
  },
  computed: {
    limitedText() {
      let str = this.text;

      if (!this.limited && this.expandable) return str;

      if (typeof str === "string" && str.length > this.limit) {
        str = str.slice(0, this.limit) + "...";
      }

      return str;
    },
  },
});
