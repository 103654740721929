
import {
  IonContent,
  IonPage,
  modalController,
  IonIcon,
  loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import ItemAddonSingle from "./ItemAddonSingle.vue";
import ItemAddonMultiple from "./ItemAddonMultiple.vue";
import _ from "lodash";
import axios from "axios";
import { closeOutline } from "ionicons/icons";
import TextLimit from "@/views/components/TextLimit.vue";
import ItemInfo from "@/views/components/ItemInfo.vue";
import QuantityButtons from "@/views/components/QuantityButtons.vue";
import Engine from 'json-rules-engine'

export default defineComponent({
  props: ["item"],
  name: "ItemDetailModal",
  setup() {
    return { closeOutline };
  },
  components: {
    IonContent,
    IonPage,
    ItemAddonSingle,
    ItemAddonMultiple,
    IonIcon,
    TextLimit,
    ItemInfo,
    QuantityButtons,
  },
  data() {
    return {
      quantity: 1,
      showAllergensDetails: false,
    };
  },
  mounted() {
    this.computeAddonVisibility();
  },
  watch: {
    selectedAddonOptions() {
      this.computeAddonVisibility();
    },
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    handleSelection(addon: any, selection: any) {
      addon.selection = selection;
    },
    async computeAddonVisibility() {
      const addonCategories = this.item.addon_categories;

      const selectedAddons = this.selectedAddonOptions.map((item: any) => { return item.addon_id });

      for (let addonCategory of addonCategories) {
        if (addonCategory.show_conditionally) {
          const meetsConditions = await this.checkMeetsRules(addonCategory.show_conditions, selectedAddons);
          addonCategory.hidden = !meetsConditions;
        }

        for (let option of addonCategory.addons) {
          if (option.show_conditionally) {
            const meetsAddonConditions = await this.checkMeetsRules(option.show_conditions, selectedAddons);
            option.hidden = !meetsAddonConditions;
          }
        }
      }


    },
    async validate() {
      let isValid = true;
      for (const el of Object.keys(this.$refs)) {
        // @ts-ignore
        if (this.$refs[el][0].validate) {
          // @ts-ignore
          const result = await this.$refs[el][0].validate()
          if (!result) {
            isValid = false;
          }
        }
      }
      console.log(isValid);
      return isValid;
    },
    async addItemToCart() {
      if (!await this.validate()) {
        return;
      }

      const loading = await loadingController.create({});
      loading.present();

      axios
        .post("/orders/in-store/item", {
          item: {
            id: this.item.id,
            name: this.item.name,
            quantity: this.quantity,
            selectedaddons: this.selectedAddonOptions,
          },
        })
        .then((resp) => {
          modalController.dismiss(resp.data);
        })
        .finally(() => {
          loading.dismiss();
        });
    },
    async checkMeetsRules(conditions: any, selectedAddons: any) {
      // @ts-ignore
      let engine = new Engine();

      // define a rule for detecting the player has exceeded foul limits.  Foul out any player who:
      // (has committed 5 fouls AND game is 40 minutes) OR (has committed 6 fouls AND game is 48 minutes)
      engine.addRule({
        conditions: conditions,
        event: {  // define the event to fire when the conditions evaluate truthy
          type: 'fouledOut',
          params: {
            isSelected: true
          }
        }
      })

      /**
       * Define facts the engine will use to evaluate the conditions above.
       * Facts may also be loaded asynchronously at runtime; see the advanced example below
       */
      let facts = {
        addonValue: selectedAddons
      }

      // Run the engine to evaluate
      const { events } = await engine.run(facts);
      return events.length > 0 && events[0].params.isSelected;
    },
  },
  computed: {
    selectedAddonOptions() {
      let selectedOpts: any = [];
      for (const addon of this.item.addon_categories) {
        if (addon.selection !== undefined && !addon.hidden) {
          selectedOpts = selectedOpts.concat(
            addon.addons
              .filter((option: any) => addon.selection.includes(option.id) && !option.hidden)
              .map(function (option: any) {
                return {
                  addon_id: option.id,
                  addon_name: option.name,
                  price: option.price,
                  addon_category_name: addon.name,
                };
              })
          );
        }
      }
      return selectedOpts;
    },
    itemTotal() {
      let total =
        parseFloat(this.item.price) +
        _.sumBy(this.selectedAddonOptions, (item: any) =>
          parseFloat(item.price)
        );
      return total * this.quantity;
    },
  },
});
