import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3fbf622"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.item.is_vegan)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "food-type-symbol",
          src: '/assets/symbols/vegan.svg'
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.item.is_veg)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "food-type-symbol",
          src: '/assets/symbols/vegetarian.svg'
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.item.spicy_level)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          class: _normalizeClass(['spicy' + _ctx.item.spicy_level, "food-type-symbol spicy1"]),
          src: '/assets/symbols/spicy1.svg'
        }, null, 10, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}