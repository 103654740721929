
import { defineComponent } from "vue";
import axios from "axios";
import { IonIcon } from "@ionic/vue";
import {
  squareOutline,
  checkbox,
  checkmarkOutline,
  arrowBackOutline,
} from "ionicons/icons";
import _ from "lodash";
import PaymentBase from "./PaymentBase.vue";
import PaymentsSummaryCard from "../PaymentsSummaryCard.vue";

export default defineComponent({
  props: ["paymentInfo"],
  name: "SelectItems",
  setup() {
    return { squareOutline, checkbox, checkmarkOutline, arrowBackOutline };
  },
  components: {
    IonIcon, PaymentsSummaryCard
  },
  mixins: [PaymentBase],
  data() {
    return {
      itemsByUser: [],
      selectedItems: [],
    };
  },
  mounted() {
    this.getCart();
    this.$events.events.on("paymentPickUpdated", (e: any) => {
      this.getCart();
    });
  },
  methods: {
    getCart() {
      axios.get("/orders/in-store/cart").then((resp) => {
        this.itemsByUser = resp.data.items_by_user;
      });
    },
    updateSelectedItems() {
      axios.post("/orders/in-store/pick-items-to-pay", {
        items_to_pay: this.selectedItems,
      });
    },
    pay() {
      this.$emit("pay", { selected_items: this.selected });
    },
    selectItem(orderItemId: number, num: number, paid: boolean) {
      if (paid) return;
      const key = `${orderItemId}-${num}`;
      //@ts-ignore
      if (this.selectedItems.includes(key)) {
        //@ts-ignore
        this.selectedItems.splice(this.selectedItems.indexOf(key), 1);
      } else {
        //@ts-ignore
        this.selectedItems.push(key);
      }
      this.$emit("change", this.selectedItems);
      this.updateSelectedItems();
    },
  },
  computed: {
    selected() {
      return this.selectedItems.map((item: any) => {
        return parseInt(item.trim().substring(0, item.trim().indexOf("-")));
      });
    },
    total() {
      return _.sumBy(this.itemsByUser, (user: any) => {
        return _.sumBy(user.items, (item: any) => {
          return _.sumBy(item.item_payments, (payment: any) => {
            //@ts-ignore
            return this.selectedItems.includes(item.id + "-" + payment.num)
              ? item.unit_price
              : 0;
          });
        });
      });
    },
  },
});
