
import {
  IonContent,
  IonPage,
  modalController,
  IonIcon,
  loadingController,
  IonItem, IonLabel, IonList,
  IonListHeader, alertController,
  IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonFab, IonFabButton, IonFabList, toastController
} from "@ionic/vue";
import { defineComponent } from "vue";
import _ from "lodash";
import axios from "axios";
import { closeOutline, add, cardOutline, cashOutline } from "ionicons/icons";

export default defineComponent({
  props: ["item"],
  name: "ItemDetailModal",
  setup() {
    return { closeOutline, add, cardOutline, cashOutline };
  },
  components: {
    IonContent,
    IonPage,
    IonItem, IonLabel, IonList, IonListHeader, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonFab, IonFabButton, IonFabList
  },
  data() {
    return {
      quantity: 1,
      showAllergensDetails: false,
      payments: [] as any,
      paymentInfo: null,
      addPaymentForm: {
        gateway_id: null,
        amount: 0
      }
    };
  },
  mounted() {
    this.getPaymentInfo();
    this.getPayments();
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    async setAsPaid(payment: any) {
      if (payment.status == 1) return;
      const alert = await alertController.create({
        header: "Vols marcar-ho com a pagat?",
        message: "",
        buttons: [
          {
            text: 'No fer res',
            role: 'cancel',
            handler: () => {
              // handlerMessage.value = 'Alert canceled';
            },
          },
          {
            text: 'Si',
            handler: async () => {
              const loading = await loadingController.create({});
              loading.present();

              axios
                .put("/orders/in-store/payment/" + payment.id, {})
                .then(async (resp) => {
                  const toast = await toastController.create({
                    message: "S'ha marcat com a pagat",
                    duration: 2500,
                    mode: "ios",
                    position: "top",
                  });

                  payment.status = 1;

                  await toast.present();
                })
                .finally(() => {
                  loading.dismiss();
                });
            },
          },
        ],
      });

      await alert.present();
    },
    async getPayments() {
      axios
        .get("/orders/in-store/payments")
        .then((resp) => {
          this.payments = resp.data;
        });
    },
    async addPayment() {
      const loading = await loadingController.create({});
      loading.present();

      axios
        .post("/orders/in-store/payments", this.addPaymentForm)
        .then((resp) => {
          this.getPayments();
          this.getPaymentInfo();
        })
        .finally(() => {
          loading.dismiss();
        });
    },
    async inputAddAmount(gatewayId: number) {
      //@ts-ignore
      this.addPaymentForm.gateway_id = gatewayId;
      //@ts-ignore
      let remaining = this.paymentInfo.total - this.paymentInfo.total_paid;
      if (remaining < 0) remaining = 0;
      const alert = await alertController.create({
        header: this.$t("payment.addPayment"),
        buttons: [
          {
            text: this.$t("payment.addPayment"),
            role: "confirm",
            handler: (form) => {
              form.amount = form.amount.replace(",", ".");
              if (isFinite(form.amount)) {
                //@ts-ignore
                this.addPaymentForm.amount = parseFloat(form.amount);
                this.addPayment();
              }
            },
          },
        ],
        inputs: [
          {
            type: "text",
            name: "amount",
            value: remaining,
            attributes: {
              inputmode: "decimal",
            },
            placeholder: "€",
          },
        ],
      });

      await alert.present().then(() => {
        const firstInput: any = document.querySelector("ion-alert input");
        firstInput.focus();
        return;
      });
    },
    getPaymentInfo() {
      axios.get("/orders/in-store/payment-info").then((resp: any) => {
        this.paymentInfo = resp.data;
      });
    },
  },
  computed: {

  },
});
