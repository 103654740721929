
import {
  IonContent,
  IonPage,
  IonModal,
  modalController,
  IonSelect,
  IonSelectOption,
  IonIcon,
  toastController,
  IonItem,
  IonToggle,
  IonLabel,
  loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import ItemDetailModal from "./components/ItemDetailModal.vue";
import VueHorizontal from "vue-horizontal";
import _ from "lodash";
import { DeviceUUID } from 'device-uuid';
import TextLimit from "@/views/components/TextLimit.vue";
import ItemInfo from "@/views/components/ItemInfo.vue";
import { languageOutline, addCircle, reloadCircle, removeCircle } from "ionicons/icons";
import EditCartItemModal from "@/views/components/EditCartItemModal.vue";

export default defineComponent({
  setup() {
    return { languageOutline, addCircle, reloadCircle, removeCircle };
  },
  name: "HomePage",
  components: {
    IonContent,
    IonPage,
    VueHorizontal,
    TextLimit,
    IonModal,
    ItemInfo,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonItem,
    IonToggle,
    EditCartItemModal,
    IonLabel
  },
  data() {
    return {
      coverImage: null as any,
      items: [],
      locales: [] as any,
      itemModal: null as any,
      activeCategoryId: null as any,
      isAdminSession: (localStorage.getItem("adminSession") !== null),
      loading: true,
      totalItems: 0,
      cartItems: 0,
      cartItemsIds: [],
      showLoginModal: false,
      languageLoaded: false,
      showAllergens: localStorage.getItem("showAllergens") !== null ? localStorage.getItem("showAllergens") == 'true' : false,
      over18: localStorage.getItem("over18") !== null ? localStorage.getItem("over18") == 'true' : true,
      hasUser: localStorage.getItem("userToken") !== null,
      form: {
        name: localStorage.getItem("name") ?? '',
      },
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#item" && to.hash === "") {
        this.closeModalItem();
      }
    },
  },
  beforeMount() {
    if (this.$route.query.tableSession) {
      //@ts-ignore
      localStorage.setItem("tableSession", this.$route.query.tableSession);
      if (localStorage.getItem("adminSession") !== null) {
        this.login();
      } else {
        this.showLoginModal = true;
      }
    } else if (localStorage.getItem("sessionId") !== null) {
      this.$events.initEvents(localStorage.getItem("sessionId"));
    }
  },
  mounted() {
    // this.getItems();
    this.$events.events.on("paymentTypeSet", (e: any) => {
      if (this.$route.name === "RestaurantHome") {
        this.getItems(false);
      }
    });
    this.$events.events.on("addedItem", async (e: any) => {
      if (this.$route.name === "RestaurantHome") {
        this.getItems(false);
      }

      const toast = await toastController.create({
        message: this.$t("home.newCartItemNotification", {
          name: e.addedByName,
          itemName: e.orderItemName,
        }),
        duration: 2500,
        mode: "ios",
        position: "top",
      });

      await toast.present();
    });
    this.$events.events.on("updatedItem", (e: any) => {
      if (this.$route.name === "RestaurantHome") {
        this.getItems(false);
      }
    });
  },
  ionViewDidEnter() {
    this.getItems(false);
  },
  methods: {
    changeUser() {
      this.hasUser = false;
      localStorage.removeItem("userToken");
    },
    async login() {
      if (this.form.name == '') {
        const toast = await toastController.create({
          message: this.$t("home.enterYourName"),
          duration: 2500,
          mode: "ios",
          color: "warning",
          position: "top",
        });

        await toast.present();
        
        return;
      }
      axios
        .post("/orders/in-store/start", {
          name: this.form.name,
          deviceId: new DeviceUUID().get()
        })
        .then((resp) => {
          //@ts-ignore
          localStorage.setItem("name", this.form.name);
          localStorage.setItem("tableSession", resp.data.session_token);
          localStorage.setItem("userToken", resp.data.user_token);

          const nextURL = "/in-place-order";
          const nextTitle = "Carta";
          const nextState = {
            additionalInformation: "Carta",
          };

          this.showLoginModal = false;

          localStorage.setItem("sessionId", resp.data.session_id);
          localStorage.setItem("showAllergens", this.showAllergens ? 'true' : 'false');
          localStorage.setItem("over18", this.over18 ? 'true' : 'false');
          this.$events.initEvents(resp.data.session_id);

          window.history.pushState(
            nextState,
            nextTitle,
            "/" +
            window.location.href
              .substring(window.location.href.lastIndexOf("/") + 1)
              .split("?")[0]
          );
          this.getItems(false);
        });
    },
    handleScroll: _.throttle(function (ev) {
      let active;

      //@ts-ignore
      for (let i = this.$refs.categoryItems.length - 1; i >= 0; i -= 1) {
        // No hash if we're near the top of the page
        if (ev.detail.scrollTop < 0) {
          active = i;
          break;
        }
        //@ts-ignore
        const item = this.$refs.categoryItems[i];

        if (
          item &&
          item.offsetTop <
          ev.detail.scrollTop + document.documentElement.clientHeight / 8 + 50
        ) {
          active = i;
          break;
        }
      }

      const lastItem =
        //@ts-ignore
        this.$refs.categoryItems[this.$refs.categoryItems.length - 1];

      var domRect = lastItem.getBoundingClientRect();
      var spaceToBottom = window.innerHeight - domRect.bottom;

      if (spaceToBottom === 0) {
        // @ts-ignore
        active = this.$refs.categoryItems.length - 1;
      }

      if (active === undefined) return;
      //@ts-ignore
      this.activeCategoryId = this.items[active].category_id;

      //@ts-ignore
      const left =
        //@ts-ignore
        this.$refs.menus[active].offsetLeft +
        //@ts-ignore
        this.$refs.menus[active].clientWidth / 2 -
        document.documentElement.clientWidth / 2;
      //@ts-ignore
      this.$refs.horizontal.scrollToLeft(left);
      // this.$refs.horizontal.scrollToIndex(active);
    }, 300),
    scrollToCategory(index: any) {
      //@ts-ignore
      document
        .querySelector("ion-content")
        //@ts-ignore
        ?.scrollToPoint(0, this.$refs.categoryItems[index].offsetTop - 40);
    },
    openCart(payment = false) {
      this.$router.push({
        path: "/in-place-order/cart",
        //@ts-ignore
        query: { payment: payment },
      });
    },
    getItems(loading = true) {
      if (loading) {
        this.loading = true;
      }
      axios
        .get("/orders/in-store/items")
        .then((resp) => {
          this.coverImage = resp.data.cover;
          this.items = resp.data.items;
          this.cartItems = resp.data.cart_items;
          this.cartItemsIds = resp.data.cart_items_ids;
          this.totalItems = resp.data.total_items;
          this.locales = resp.data.locales;
          //@ts-ignore
          this.$globalEvents.emit('waiterRequests', resp.data.waiter_requests);

          if (this.locales.length > 0 && !this.languageLoaded) { 
            this.languageLoaded = true;
            if (this.locales.filter((locale: string) => { return locale === this.$i18n.locale }).length === 0) {
              this.$i18n.locale = this.locales[0];
              this.setLang();
            }
          }

          //@ts-ignore
          this.activeCategoryId = this.items[0].category_id;

          if (
            resp.data.payment_required &&
            localStorage.getItem("sessionId") !== null
          ) {
            this.openCart(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 410 || err.response.status === 420) {
            this.showLoginModal = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async quickAddItem(item: any, ev: any) {
      ev.stopPropagation();
      if (item.addon_categories.length > 0) {
        this.openItem(item);
      } else {
        // const loading = await loadingController.create({});
        // loading.present();

        item.loading = true;
        axios
          .post("/orders/in-store/item", {
            item: {
              id: item.id,
              name: item.name,
              quantity: 1,
              selectedaddons: [],
            },
          })
          .then((resp) => {
            this.cartItems = resp.data.cart_items;
            this.cartItemsIds = resp.data.cart_items_ids;
          })
          .finally(() => {
            // loading.dismiss();
            item.loading = false;
          });
      }
    },
    handleEditedCartItem(data: any) {
      this.cartItems = data.cart_items;
      this.cartItemsIds = data.cart_items_ids;
    },
    async quickRemoveItem(item: any, ev: any) {
      ev.stopPropagation();
      //@ts-ignore
      this.$refs.editCartItemModal.editItem(item, true);
    },
    async openItem(item: any) {
      this.itemModal = await modalController.create({
        component: ItemDetailModal,
        componentProps: {
          item: item,
        },
      });
      window.location.hash = "item";

      this.itemModal.onDidDismiss().then((resp: any) => {
        if (resp.data) {
          this.cartItems = resp.data.cart_items;
          this.cartItemsIds = resp.data.cart_items_ids;
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
      });

      return await this.itemModal.present();
    },
    async closeModalItem() {
      return await this.itemModal.dismiss();
    },
    setLang() {
      localStorage.setItem("locale", this.$i18n.locale);
      if (this.$i18n.locale === 'ar') {
        //@ts-ignore
        document.querySelector('body').classList.add('rtl');
      } else {
        //@ts-ignore
        document.querySelector('body').classList.remove('rtl');
      }
    },
  },
});
