
import { defineComponent } from "vue";
// import { NumberSpinner } from "vue3-number-spinner";
import { alertController } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
// import axios from "axios";
import _ from "lodash";

function roundWithMaxPrecision(n: number, precision = 1) {
  const precisionWithPow10 = Math.pow(10, precision);
  return Math.round(n * precisionWithPow10) / precisionWithPow10;
}

export default defineComponent({
  props: ["total"],
  name: "TipComponent",
  data() {
    return {
      selectedAmount: 0,
      options: [0, 1, 2] as any,
    };
  },
  watch: {
    selectedAmount() {
      this.$emit("tip-updated", this.selectedAmount);
    },
    total() {
      this.calculateOptions();
    },
  },
  setup() {
    return { arrowBackOutline };
  },
  components: {
    // NumberSpinner,
    // IonIcon,
  },
  beforeMount() {
    this.calculateOptions();
  },
  // mounted() {

  // },
  methods: {
    calculateOptions() {
      const total = this.total > 3 ? this.total : 3;
      let options = [] as any;
      options.push(0);
      options.push(roundWithMaxPrecision((total * 5) / 100));
      options.push(roundWithMaxPrecision((total * 10) / 100));
      options.push(roundWithMaxPrecision((total * 15) / 100));
      this.options = options;
    },
    async inputCustomAmount() {
      const alert = await alertController.create({
        header: this.$t("payment.tipSetTitle"),
        buttons: [
          {
            text: this.$t("payment.tipSetBtn"),
            role: "confirm",
            handler: (form) => {
              form.amount = form.amount.replace(",", ".");
              if (isFinite(form.amount)) {
                this.selectedAmount = parseFloat(form.amount);
              }
            },
          },
        ],
        inputs: [
          {
            type: "text",
            name: "amount",
            attributes: {
              inputmode: "decimal",
            },
            placeholder: "€",
          },
        ],
      });

      await alert.present().then(() => {
        const firstInput: any = document.querySelector("ion-alert input");
        firstInput.focus();
        return;
      });
    },
  },
  computed: {
    amountOptions() {
      let options = [...this.options];
      if (this.selectedAmount && !options.includes(this.selectedAmount)) {
        options.push(this.selectedAmount);
      }
      return _(options).sortBy().value();
    },
  },
});
