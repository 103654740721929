import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33b5d50d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "items-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "item-check" }
const _hoisted_4 = {
  key: 0,
  class: "check"
}
const _hoisted_5 = {
  key: 1,
  class: "check"
}
const _hoisted_6 = {
  key: 2,
  class: "check"
}
const _hoisted_7 = { class: "item-content" }
const _hoisted_8 = { class: "item-price" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_tip = _resolveComponent("tip")!
  const _component_payments_summary_card = _resolveComponent("payments-summary-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.paymentInfo.payment_type === null)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn-back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
        }, [
          _createVNode(_component_ion_icon, { icon: _ctx.arrowBackOutline }, null, 8, ["icon"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("payment.back")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("payment.paymentType2Sub")), 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsByUser, (user) => {
        return (_openBlock(), _createElementBlock("div", {
          key: user.id
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(user.items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.item_payments, (payment) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["item", {
            paid: payment.paid || payment.pre_selected,
            selected: _ctx.selectedItems.includes(item.id + '-' + payment.num),
          }]),
                  key: payment.num,
                  onClick: ($event: any) => (
            _ctx.selectItem(
              item.id,
              payment.num,
              payment.paid || payment.pre_selected
            )
          )
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.selectedItems.includes(item.id + '-' + payment.num))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          _createVNode(_component_ion_icon, { icon: _ctx.checkbox }, null, 8, ["icon"])
                        ]))
                      : _createCommentVNode("", true),
                    (
                !_ctx.selectedItems.includes(item.id + '-' + payment.num) &&
                !payment.paid
              )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _createVNode(_component_ion_icon, { icon: _ctx.squareOutline }, null, 8, ["icon"])
                        ]))
                      : _createCommentVNode("", true),
                    (payment.paid)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(item.name), 1),
                  _createElementVNode("div", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.$filters.currency(item.unit_price)) + " ", 1),
                    (payment.paid_by)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(payment.paid_by), 1))
                      : _createCommentVNode("", true),
                    (payment.pre_selected_by)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(payment.pre_selected_by), 1))
                      : _createCommentVNode("", true)
                  ])
                ], 10, _hoisted_2))
              }), 128))
            ]))
          }), 128))
        ]))
      }), 128))
    ]),
    _createVNode(_component_tip, {
      total: _ctx.total,
      onTipUpdated: _ctx.handleTipUpdated
    }, null, 8, ["total", "onTipUpdated"]),
    _createVNode(_component_payments_summary_card),
    _createElementVNode("button", {
      class: "primary-btn",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pay && _ctx.pay(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("payment.pay")) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$filters.currency(_ctx.total + _ctx.tip)), 1)
    ]),
    _createElementVNode("small", {
      class: "payment-alert",
      innerHTML: _ctx.$t('payment.conditions')
    }, null, 8, _hoisted_11)
  ]))
}