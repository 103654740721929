import { createI18n } from "vue-i18n";
import messages from "./i18n/messages";

export default function setupI18n() {
    const i18n = createI18n({
        locale: localStorage.getItem("locale") ?? "ca", // set locale
        fallbackLocale: "ca", // set fallback locale
        messages, // set locale messages
    });

    //@ts-ignore
    window.i18n = i18n;

    return i18n;
}