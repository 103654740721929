import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2060929f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding edit-order-item-content" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "current-qtt-label" }
const _hoisted_6 = {
  key: 0,
  class: "addons"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "addons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_quantity_buttons = _resolveComponent("quantity-buttons")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.showCartItemEditModal,
    "initial-breakpoint": 1,
    breakpoints: [1],
    handle: false,
    onDidDismiss: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showCartItemEditModal = false))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ion_spinner)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCartItemEditModal = false)),
                class: "bottom-modal-close-btn"
              }, [
                _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
              ]),
              (_ctx.showSelectCartItem)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.$t("cart.chooseProduct")), 1),
                    _createVNode(_component_ion_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartItems, (cartItem) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: cartItem.id,
                            onClick: ($event: any) => {_ctx.showSelectCartItem = false; _ctx.editCartItem(cartItem, true)}
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, "x" + _toDisplayString(cartItem.quantity), 1),
                              _createElementVNode("div", null, [
                                _createElementVNode("div", null, _toDisplayString(cartItem.name), 1),
                                (cartItem.addons.length > 0)
                                  ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(cartItem.addons.map((entry) =>
                    entry.addon_name).join(", ")), 1))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.showSelectCartItem)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.itemToEdit.name), 1),
                    (_ctx.itemToEdit.addons.length > 0)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.itemToEdit.addons.map((entry) =>
              entry.addon_name).join(", ")), 1))
                      : _createCommentVNode("", true),
                    _createVNode(_component_quantity_buttons, {
                      modelValue: _ctx.itemToEdit.quantity,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemToEdit.quantity) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("button", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateCartItem(_ctx.itemToEdit))),
                      class: "btn-action btn-update-qtt"
                    }, _toDisplayString(_ctx.$t("cart.updateQtt")), 1),
                    _createElementVNode("button", {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeCartItem(_ctx.itemToEdit))),
                      class: "btn-action btn-delete"
                    }, _toDisplayString(_ctx.$t("cart.delete")), 1),
                    _createElementVNode("button", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCartItemEditModal = false)),
                      class: "btn-action btn-cancel"
                    }, _toDisplayString(_ctx.$t("cart.cancel")), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["is-open"]))
}