
import { defineComponent } from "vue";
import EditCartItemModal from "@/views/components/EditCartItemModal.vue";

export default defineComponent({
  props: ["itemsByUser", "payNow"],
  name: "OrderItems",
  components: {
    EditCartItemModal
  },
  data() {
    return {
      openedUsers: [] as any,
    };
  },
  watch: {
    itemsByUser(newItemsByUser) {
      if (this.payNow) {
        for (let user of newItemsByUser) {
          this.openedUsers.push(user.id);
        }
      }
    },
  },
  methods: {
    toggleUser(user: any) {
      if (this.openedUsers.includes(user.id)) {
        this.openedUsers.splice(this.openedUsers.indexOf(user.id), 1);
      } else {
        this.openedUsers.push(user.id);
      }
    },
  },
});
