
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default function setupErrorTracking(app: any, router: any) {

   Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", "app.dinbook.es", "192.168.0.229", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
   });
    
}