import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f963f48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-detail-container" }
const _hoisted_2 = {
  key: 0,
  class: "img-container"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "item-container" }
const _hoisted_6 = { class: "desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_item_info = _resolveComponent("item-info")!
  const _component_text_limit = _resolveComponent("text-limit")!
  const _component_item_addon_single = _resolveComponent("item-addon-single")!
  const _component_item_addon_multiple = _resolveComponent("item-addon-multiple")!
  const _component_quantity_buttons = _resolveComponent("quantity-buttons")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["close-container", { 'has-image': _ctx.item.image }])
          }, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            }, [
              _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
            ])
          ], 2),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.item.image)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("img", {
                    src: _ctx.item.image_thumb
                  }, null, 8, _hoisted_3),
                  _createElementVNode("img", {
                    src: _ctx.item.image,
                    style: {"position":"absolute"}
                  }, null, 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h1", null, [
                _createTextVNode(_toDisplayString(_ctx.item.name) + " ", 1),
                _createVNode(_component_item_info, { item: _ctx.item }, null, 8, ["item"])
              ]),
              _createElementVNode("p", _hoisted_6, [
                _createVNode(_component_text_limit, {
                  text: _ctx.item.desc,
                  limit: 500
                }, null, 8, ["text"])
              ])
            ]),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.addon_categories, (addon) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: addon.id,
                  class: _normalizeClass({ 'addon-hidden': addon.hidden !== undefined && addon.hidden === true })
                }, [
                  (addon.type === 'SINGLE')
                    ? (_openBlock(), _createBlock(_component_item_addon_single, {
                        key: 0,
                        ref_for: true,
                        ref: 'addonSingle' + addon.id,
                        addon: addon,
                        onChange: ($event: any) => (_ctx.handleSelection(addon, $event)),
                        hidden: addon.hidden !== undefined && addon.hidden === true
                      }, null, 8, ["addon", "onChange", "hidden"]))
                    : _createCommentVNode("", true),
                  (addon.type === 'MULTI')
                    ? (_openBlock(), _createBlock(_component_item_addon_multiple, {
                        key: 1,
                        addon: addon,
                        onChange: ($event: any) => (_ctx.handleSelection(addon, $event))
                      }, null, 8, ["addon", "onChange"]))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ]),
            (_ctx.item && _ctx.item.allergens.length > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["allergens", { extended: _ctx.showAllergensDetails }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAllergensDetails = !_ctx.showAllergensDetails))
                }, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t("itemDetail.allergens")), 1),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.allergens, (allergen) => {
                      return (_openBlock(), _createElementBlock("li", { key: allergen }, [
                        _createElementVNode("i", {
                          class: _normalizeClass('eicon eicon-circle-' + allergen)
                        }, null, 2),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("allergens." + allergen)), 1)
                      ]))
                    }), 128))
                  ])
                ], 2))
              : _createCommentVNode("", true),
            _createVNode(_component_quantity_buttons, {
              modelValue: _ctx.quantity,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.quantity) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("button", {
              class: "add-btn",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addItemToCart && _ctx.addItemToCart(...args)))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t("itemDetail.addToOrder")) + " ", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$filters.currency(_ctx.itemTotal)), 1)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}