
import { defineComponent } from "vue";
import { IonModal, modalController } from "@ionic/vue";
import axios from "axios";
import SplitBill from "@/views/components/payment-types/SplitBill.vue";
import SelectItems from "@/views/components/payment-types/SelectItems.vue";
import PayAll from "@/views/components/payment-types/PayAll.vue";
import PayMyItems from "@/views/components/payment-types/PayMyItems.vue";
import { IonIcon } from "@ionic/vue";
import { ellipseOutline, checkmarkCircleOutline, close } from "ionicons/icons";

export default defineComponent({
  props: ["itemsByUser", "comment"],
  setup() {
    return { ellipseOutline, checkmarkCircleOutline, close };
  },
  components: {
    IonModal,
    SplitBill,
    SelectItems,
    PayAll,
    PayMyItems,
    IonIcon,
  },
  data() {
    return {
      isOpen: false,
      isIframeOpen: false,
      breakPoints: [1],
      paymentInfo: null as any,
      selectedPaymentType: null,
      paymentUrl: null,
      tip: 0,
    };
  },
  name: "PaymentModal",
  watch: {
    $route(to, from) {
      if (from.hash === "#payment" && to.hash === "") {
        this.isIframeOpen = false;
      }
    },
  },
  mounted() {
    this.$events.events.on("userHasPaid", (e: any) => {
      this.getPaymentInfo();
    });
  },
  methods: {
    pay(extra_data = {}) {
      axios
        .post(
          "/orders/in-store/pay",
          Object.assign(
            {
              payment_type: this.selectedPaymentType,
              tip: this.tip,
              comment: this.comment,
              locale: this.$i18n.locale
            },
            extra_data
          )
        )
        .then((resp) => {
          console.info(resp.data);
          this.paymentUrl = resp.data.payment_link;
          this.isIframeOpen = true;
          window.location.hash = "payment";

          this.$echo
            .channel("payment." + resp.data.code)
            .listen("PaymentSuccess", async () => {
              // this.isIframeOpen = false;
              // this.isOpen = false;
              window.location.replace('/payment-confirmation');
              // this.$emit('paymentSuccess');
              // this.$nextTick(() => {
              //   this.$router.replace({ path: "/payment-confirmation" });
              // });
            })
            .listen("PaymentFail", (e: any) => {
              console.info("PaymentFail", e);
              alert(this.$t("payment.error"));
              this.isIframeOpen = false;
              this.isOpen = false;
            });
        });
    },
    async dismiss() {
      //@ts-ignore
      await this.$refs.modal.$el.dismiss();
    },
    getPaymentInfo() {
      axios.get("/orders/in-store/payment-info").then((resp: any) => {
        this.paymentInfo = resp.data;
        if (this.paymentInfo.paid) {
          return;
        }
        if (this.paymentInfo.payment_type !== null) {
          this.selectedPaymentType = this.paymentInfo.payment_type;
          if (this.selectedPaymentType === 2) {
            setTimeout(() => {
              this.breakPoints = [1];
              this.$nextTick(() => {
                //@ts-ignore
                this.$refs.modal.$el.setCurrentBreakpoint(1);
              });
            }, 1000);
          }
        }
      });
    },
    savePaymentType() {
      return axios.post("/orders/in-store/payment-type", {
        payment_type: this.selectedPaymentType,
      });
    },
    selectPaymentType(paymentType: any) {
      this.selectedPaymentType = paymentType;
    },
    async setPaymentType(paymentType: any) {
      this.selectedPaymentType = paymentType;
      await this.savePaymentType();
      // if ([0, 3].includes(paymentType)) {
      //   this.pay();
      // }
    },
    open() {
      //@ts-ignore
      this.isOpen = true;
      this.$nextTick(() => {
        //@ts-ignore
        this.$refs.modal.$el.setCurrentBreakpoint(1);
      });

      // console.info();
    },
  },
});
